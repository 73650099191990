import React from "react"

const Seeyou = () => {
  return (
    <div
      id="seeyou"
      className="seeyou section-padding bg-img bg-fixed"
    >
      <div className="container">
        <div className="row">
          <div className="section-head col-md-12 text-center">
            <span>
              <i className="ti-heart"></i>
            </span>
            <h4>Looking forward to see you!</h4>
            <h3>29.10.2021</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Seeyou
