import React, { useEffect } from 'react'

const Countdown = () => {
  useEffect(() => {
    (function() {
      const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;
      let birthday = "Oct 29, 2021 10:00:00",
          countDown = new Date(birthday).getTime(),
          x = setInterval(() => {
            let now = new Date().getTime(),
                distance = countDown - now;

            document.getElementById("days").innerText = Math.floor(distance / (day));
            document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour));
            document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute));
            document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);

            //do something later when date is reached
            if (distance < 0) {
              let headline = document.getElementById("headline")

              headline.innerText = "We are Married!"

              clearInterval(x);
            }
            //seconds
          }, 0);
          return null;
      }());
  }, [])

  return (
    <div
      id='countdown'
      className='section-padding bg-img bg-fixed'
      data-background='images/banner-1.jpg'
    >
      <div className='container'>
        <div className='row'>
          <div className='section-head col-md-12'>
            <h4 id="headline">We will become a family in</h4>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <ul>
              <li>
                <span id='days'></span>Days
              </li>
              <li>
                <span id='hours'></span>Hours
              </li>
              <li>
                <span id='minutes'></span>Minutes
              </li>
              <li>
                <span id='seconds'></span>Seconds
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Countdown
