import React from "react"

const Header = () => {
  return (
    <header
      id="home"
      className="header valign bg-img parallaxie"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center caption">
            <h1 className="animate-box colorHeader" data-animate-effect="fadeInUp">
              Shemwel & Rolin
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="arrow bounce text-center">
              <a href="#couple">
                {" "}
                <i className="ti-heart"></i>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
