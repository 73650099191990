import React from "react"
import gallery1 from "../assets/images/1.jpeg"
import gallery2 from "../assets/images/2.jpeg"
import gallery3 from "../assets/images/3.jpeg"
import gallery4 from "../assets/images/4.jpeg"
import gallery5 from "../assets/images/5.jpeg"
import gallery6 from "../assets/images/6.jpeg"

const Gallery = () => {
  return (
    <div id="gallery" className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-30">
            <span className="oliven-title-meta">Gallery</span>
            <h2 className="oliven-title">Pre Wedding</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gallery-filter mt-3">
          <div className="col-md-4 gallery-item ceremony">
            <a href="/" className="img-zoom">
              <div className="gallery-box">
                <div className="gallery-img">
                  {" "}
                  <img
                    src={gallery1}
                    className="img-fluid mx-auto d-block"
                    alt=""
                  />{" "}
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4 gallery-item party">
            <a href="/" className="img-zoom">
              <div className="gallery-box">
                <div className="gallery-img">
                  {" "}
                  <img
                    src={gallery2}
                    className="img-fluid mx-auto d-block"
                    alt=""
                  />{" "}
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4 gallery-item ceremony">
            <a href="/" className="img-zoom">
              <div className="gallery-box">
                <div className="gallery-img">
                  {" "}
                  <img
                    src={gallery3}
                    className="img-fluid mx-auto d-block"
                    alt=""
                  />{" "}
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4 gallery-item party">
            <a href="/" className="img-zoom">
              <div className="gallery-box">
                <div className="gallery-img">
                  {" "}
                  <img
                    src={gallery4}
                    className="img-fluid mx-auto d-block"
                    alt=""
                  />{" "}
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4 gallery-item ceremony">
            <a href="/" className="img-zoom">
              <div className="gallery-box">
                <div className="gallery-img">
                  {" "}
                  <img
                    src={gallery5}
                    className="img-fluid mx-auto d-block"
                    alt=""
                  />{" "}
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-4 gallery-item party">
            <a href="/" className="img-zoom">
              <div className="gallery-box">
                <div className="gallery-img">
                  {" "}
                  <img
                    src={gallery6}
                    className="img-fluid mx-auto d-block"
                    alt=""
                  />{" "}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
